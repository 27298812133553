import React from "react";



function Page404() {
    return (
       <div className="Page404">
       <div className="bg">
        <h1 style={{fontSize: "200px"}}>404</h1>
        <h2>PAGE NOT FOUND.</h2>
        <a href= "/"><h3>RETURN TO HOMEPAGE</h3></a>
        </div>
       </div>
    )
}

export default Page404;