export const projects = [

    {
        id: 1,
        img: require('./assets/img/ecom.png'),
        link: 'https://github.com/HypCarlos/E-Commerce-Clone',
        name: 'E-Commerce site'
    },
    {
        id: 2,
        img: require("./assets/img/father.png"),
        link: 'https://fatherholdmynotepad.com/',
        name: 'Kanye Meme'
    },
    {
        id: 3,
        img: require("./assets/img/patentify.png"),
        link: 'https://patentify.cognac.cs.fiu.edu/Home',
        name: 'Capstone Project'
    },
    {
        id: 4,
        img: require("./assets/img/Spotify-api.png"),
        link: 'https://github.com/HypCarlos/Spotify-Api',
        name: 'Spotify-Api'
    },
 
   
 
]